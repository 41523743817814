import React from 'react'
import { Spinner, SpinnerProps } from '@chakra-ui/react'
import styles from './Loader.module.scss'

interface LoaderProps extends Pick<SpinnerProps, 'size'> {
  withBackground?: boolean
}

export const Loader: React.FC<LoaderProps> = ({ withBackground, size }) => {
  return (
    <div className={styles.container}>
      {withBackground && <div className={styles.background} />}
      <Spinner speed="0.75s" size={size} color="var(--m-Neutral-20)" />
    </div>
  )
}
