import ArrowDownIcon from './assets/arrow-down-icon.svg'
import BellIcon from './assets/bell-icon.svg'
import BombIcon from './assets/bomb-icon.svg'
import CalendarIcon from './assets/calendar-icon.svg'
import ContactIcon from './assets/contact-icon.svg'
import CoordinatesIcon from './assets/coordinates-icon.svg'
import CrimeIcon from './assets/crime-icon.svg'
import DashboardIcon from './assets/dashboard-icon.svg'
import DocumentMapIcon from './assets/document-map-icon.svg'
import EngineWarningIcon from './assets/engine-warning.svg'
import ExplosionIcon from './assets/explosion-icon.svg'
import FilterIcon from './assets/filter-icon.svg'
import InfoIcon from './assets/info-icon.svg'
import LocationIcon from './assets/location-icon.svg'
import LogoFullIcon from './assets/logo-full-icon.svg'
import LogoIcon from './assets/logo-icon.svg'
import LogOutIcon from './assets/logout-icon.svg'
import MapIcon from './assets/map-icon.svg'
import MegaphoneIcon from './assets/megaphone-icon.svg'
import MenuIcon from './assets/menu-icon.svg'
import NewspaperIcon from './assets/newspaper-icon.svg'
import ReportIcon from './assets/reports-icon.svg'

export {
  EngineWarningIcon,
  MegaphoneIcon,
  CrimeIcon,
  ExplosionIcon,
  BombIcon,
  ArrowDownIcon,
  LocationIcon,
  LogoIcon,
  LogoFullIcon,
  LogOutIcon,
  BellIcon,
  MenuIcon,
  MapIcon,
  ReportIcon,
  InfoIcon,
  DashboardIcon,
  FilterIcon,
  NewspaperIcon,
  DocumentMapIcon,
  CalendarIcon,
  CoordinatesIcon,
  ContactIcon,
}
